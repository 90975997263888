import { styled } from "@mui/material";
import type { PictureSchema } from "~/schemas";

interface DtwImageProps {
  picture: PictureSchema;
  alt: string;
  className?: string;
  objectFit?: "contain" | "cover";
  style?: React.CSSProperties;
}

function DtwImage({
  picture,
  alt,
  className,
  objectFit,
  style,
}: DtwImageProps) {
  return (
    <picture
      className={className}
      style={{
        objectFit: objectFit ?? "cover",
        display: "flex",
        ...style,
      }}
    >
      {!!picture?.min1024 && (
        <source
          media="(min-width: 1024px)"
          srcSet={picture?.min1024}
          style={{ objectFit: objectFit ?? "cover", maxWidth: "100%" }}
        />
      )}
      {!!picture?.min768 && (
        <source
          media="(min-width: 768px)"
          srcSet={picture?.min768}
          style={{ objectFit: objectFit ?? "cover", maxWidth: "100%" }}
        />
      )}
      {!!picture && (
        <img
          src={picture.default}
          alt={alt}
          className={className}
          style={{ objectFit: objectFit ?? "cover", maxWidth: "100%" }}
        />
      )}
      {!picture && (
        <img
          src={"/assets/fashionshop/placeholder.png"}
          alt={alt}
          className={className}
          style={{ objectFit: objectFit ?? "cover", maxWidth: "100%" }}
        />
      )}
    </picture>
  );
}

export default styled(DtwImage)``;
