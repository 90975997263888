import { produce } from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { Product } from "./schemas";
import type { ProductJson } from "./jsonSchemas";

export const NO_VARIATION = "none";

export function getPriceForVariation(
  product: Product | ProductJson,
  variationId: string
): number {
  if (variationId === NO_VARIATION || !product.variations) {
    return product.currentPrice!;
  }

  const variation = product.variations?.find((v) => v.id === variationId);
  if (!variation) {
    return product.currentPrice!;
  }

  return variation.currentPrice || product.currentPrice || 0;
}

export function getOldPriceForVariation(
  product: Product | ProductJson,
  variationId: string
): number | undefined {
  if (variationId === NO_VARIATION || !product.variations) {
    return product.oldPrice;
  }

  const variation = product.variations?.find((v) => v.id === variationId);
  if (!variation) {
    return product.oldPrice!;
  }

  return variation.oldPrice || product.oldPrice || 0;
}

interface CartStoreState {
  cart: { [productId: string]: { [variationId: string]: number } };
  total: number;
  incrementCartQty(
    product: Product | ProductJson,
    variationId: string,
    increment?: number
  ): void;
  decrementCartQty(product: Product | ProductJson, variationId: string): void;
  removeFromCart(product: Product | ProductJson, variationId: string): void;
  clearCart(): void;
  recalculateCart(products: (Product | ProductJson)[]): void;
}

export const useCartStore = create<CartStoreState>()(
  persist(
    (set) => ({
      cart: {},
      total: 0,
      incrementCartQty(product, variationId, increment) {
        set(
          produce((state) => {
            const inc = increment || 1;
            if (!state.cart[product.id]) {
              state.cart[product.id] = {};
            }
            if (!state.cart[product.id][variationId]) {
              state.cart[product.id][variationId] = inc;
            } else {
              state.cart[product.id][variationId] += inc;
            }

            state.total = (
              getPriceForVariation(product, variationId) * inc +
              Number(state.total)
            ).toFixed(2);
            return state;
          })
        );
      },
      decrementCartQty(product, variationId) {
        set(
          produce((state) => {
            if (
              !state.cart[product.id] ||
              !state.cart[product.id][variationId]
            ) {
              return state;
            }

            state.total = (
              state.total - getPriceForVariation(product, variationId)
            ).toFixed(2);
            if (state.cart[product.id][variationId] === 1) {
              delete state.cart[product.id][variationId];

              if (Object.keys(state.cart[product.id]).length === 0) {
                // If no variations remain in cart, remove the product itself.
                delete state.cart[product.id];
              }

              if (Object.keys(state.cart).length === 0) {
                // Reset from floating point errors that can happen.
                state.total = 0;
              }
              return state;
            }

            state.cart[product.id][variationId]--;
            return state;
          })
        );
      },
      removeFromCart(product, variationId) {
        set(
          produce((state) => {
            if (
              !state.cart[product.id] ||
              !state.cart[product.id][variationId]
            ) {
              return state;
            }

            const qty = state.cart[product.id][variationId];
            state.total = (
              state.total -
              getPriceForVariation(product, variationId) * qty
            ).toFixed(2);
            delete state.cart[product.id][variationId];
            if (Object.keys(state.cart[product.id]).length === 0) {
              // If no variations remain in cart, remove the product itself.
              delete state.cart[product.id];
            }

            if (Object.keys(state.cart).length === 0) {
              // Reset from floating point errors that can happen.
              state.total = 0;
            }

            return state;
          })
        );
      },
      clearCart() {
        set((s) => ({ ...s, cart: {}, total: 0 }));
      },
      recalculateCart(products) {
        set(
          produce((state) => {
            // Remove deleted products from cart and update total to match new prices
            let cartValue = 0;
            for (const pId in state.cart) {
              const product = products.find((p) => p.id === pId);
              if (!product) {
                delete state.cart[pId];
                continue;
              }
              for (const variationId in state.cart[pId]) {
                if (variationId !== NO_VARIATION) {
                  const variation = product.variations?.find(
                    (v) => v.id === variationId
                  );
                  if (!variation) {
                    delete state.cart[pId][variationId];
                    if (Object.keys(state.cart[pId]).length === 0) {
                      // If no variations remain in cart, remove the product itself.
                      delete state.cart[pId];
                    }
                    continue;
                  }
                }

                const qty = state.cart[pId][variationId];
                cartValue += getPriceForVariation(product, variationId) * qty;
              }
            }
            state.total = cartValue;
          })
        );
      },
    }),
    {
      name: "store-storage",
    }
  )
);
